/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { StyledTableBodyTr, StyledTd, StyledTdItem } from 'common/styles/common.styles';
import React from 'react';
import { StyledMonitorVaultsCoinName } from './strategiesTable.style';
import { RebalancingStrategies } from 'common/models/strategies';
import Link from '../Link';
import { CryptoUtils } from 'utils/cryptoUtils';
import StrategiesTablePositionDetails from './StrategiesTablePositionDetails';
import { bnToNumberWithoutDecimals } from '@ichidao/ichi-sdk/dist/src/utils/number';
import { VAULTS } from '@ichidao/ichi-sdk/dist/src/constants/vaults';
import FormatFloat from '../FormatPercent/FormatFloat';

type Props = {
  strategy: RebalancingStrategies;
  tvl: number;
};

function hasNumber(myString: string): boolean {
  return /[015]/.test(myString);
}

export function getVaultDisplayName(name: string): string {
  const arrName = name.split('_');
  const len = arrName.length;
  if (len < 2) return name.toUpperCase();
  if (hasNumber(name) && len > 2) {
    return `${arrName[len-3]}-${arrName[len-2]} ${arrName[len-1]}%`.toUpperCase();
  } else {
    return `${arrName[len-2]}-${arrName[len-1]}`.toUpperCase();
  }
}

const MonitorVaultsTablePosition: React.FC<Props> = (props) => {
  const [isStrategySelected, setIsStrategySelected] = React.useState(false);
  const toggleSelected = () => {
    setIsStrategySelected(!isStrategySelected);
  };

  const vaultName = props.strategy.vaultName || '';

  const chainLinkBalance = bnToNumberWithoutDecimals(props.strategy.chainLinkBalance, 18).toFixed(1);
  const chainLinkMinBalance = bnToNumberWithoutDecimals(props.strategy.chainLinkMinBalance, 18).toFixed(1);
  const lowBalance = 
    parseFloat(chainLinkBalance) < parseFloat(chainLinkMinBalance) && parseFloat(chainLinkBalance) > 0 
  const balanceTextColor = lowBalance ? 'alert-text-color' : '';
  const showMinBalance = lowBalance ? `(min ${chainLinkMinBalance})` : '';
  const pausedTextColor = props.strategy.paused ? 'alert-text-color': 'primary-text-color';

  return (
    <>
      <StyledTableBodyTr
        className='table-border-bottom cursor-pointer'
        key={vaultName}
        onClick={toggleSelected}
      >
        {/* ---------------------- */}
        {/* Name */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color">
            <StyledMonitorVaultsCoinName className="primary-text-color">
              <span>{getVaultDisplayName(props.strategy.vaultName)}</span>
            </StyledMonitorVaultsCoinName>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Paused */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className={`${pausedTextColor} pb-3`}>
            {props.strategy.paused ? 'Yes' : 'No'}
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* ChainLink */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <Link href={props.strategy.chainLink} target='blank'>Link</Link>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* ChainLink balance */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <span className={balanceTextColor}>{chainLinkBalance} {showMinBalance}</span>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* TVL */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatFloat 
              value={props.tvl} 
              prefix='$' 
              displayDecimals={2}
            />
          </StyledTdItem>
        </StyledTd>
      </StyledTableBodyTr>
      <StrategiesTablePositionDetails
        isShown={isStrategySelected}
        strategy={props.strategy}
      />
    </>
  );
};

export default MonitorVaultsTablePosition;
